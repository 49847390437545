import { styled } from '@mui/material/styles';
import Grid, { GridProps } from '@mui/material/Grid';

import { colors } from '../../styles/_colors';
import MaxWidthBox from '../max_width_box';
import { variables } from '../../styles/_variables';

export interface SunnysideGridProps extends GridProps {
    background?: any,
    maxwidthgrid?: boolean,
    maxwidthwrapper?: boolean,
    noPadding?: boolean,
    children?: any,
}

export default function SunnysideGrid(props: SunnysideGridProps): JSX.Element {
    const {
        children,
        background,
        maxwidthgrid,
        maxwidthwrapper,
        noPadding,
        padding,
        paddingBottom,
        paddingTop,
        paddingLeft,
        paddingRight
    } = props;
    const StyledGrid = styled(Grid, {
        shouldForwardProp: (prop) =>
            prop !== 'maxwidthgrid' 
            && prop !== 'maxwidthwrapper' 
            && prop !== 'noPadding',
    })<GridProps>(({ theme }) => ({
        width: '100%',
        background: background || colors.cloud,
        maxWidth: maxwidthgrid && variables.maxWidth,
        margin: maxwidthgrid && variables.maxWidthMargin,
        padding: (noPadding && '0rem !important'),
        [theme.breakpoints.down(400)]: {
            padding: variables.xsPadding,
        },
        [theme.breakpoints.up(400)]: {
            padding: variables.phonePadding,
        },
        [theme.breakpoints.up('sm')]: {
            padding: padding || variables.desktopPadding,
            paddingBottom: paddingBottom,
            paddingTop: paddingTop,
            paddingRight: paddingRight,
            paddingLeft: paddingLeft
        },
    }));
    if (maxwidthwrapper) {
        return (
            <StyledGrid container item xs={12} {...props}>
                <MaxWidthBox>
                    {children}
                </MaxWidthBox>
            </StyledGrid>
        );
    } else {
        return (
            <StyledGrid container item xs={6} sm={12} {...props}>{children}</StyledGrid>
        );
    }
}