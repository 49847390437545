import { createHash } from 'crypto';
import { v4 } from 'uuid';
import localeCode from 'locale-code';
import { post } from '../networking';
import dataDogLogger from './datadog';

const ENVIRONMENT = process.env.NEXT_PUBLIC_NENV;
const trackingEnvironments = ['production'];

export type CustomDataDto = {
    currency?: string, // ISO 4217 currency code
    value?: number // float
}

export type FacebookTrackingUserData = {
    em: string,
    ph: string,
    fn: string,
    ge: string,
    external_id: string, // aka user id
    country: string,
    client_user_agent?: string,
    fbc?: string,
    fbp?: string,
    value?: number,
    currency?: string
}

export type FacebookTrackingParams = {
    event_name: string,
    event_id: string,
    event_time: number,
    action_source: string,
    event_source_url: string,
    user_data: FacebookTrackingUserData,
    custom_data?: CustomDataDto
}

function fbqProxy(event_name: string, params: FacebookTrackingUserData, event_id: string) {
    const fbq = window && window['fbq'];
    if(typeof fbq !== 'function') {
        return;
    }

    if(!trackingEnvironments.includes(ENVIRONMENT)) {
        // log to console and return
        console.log('FB Pixel', event_name, event_id, params);
        return;
    }

    fbq(
        'trackSingle',
        '276794426891651',
        event_name,
        params,
        {
            eventID: event_id
        },
    );
}

async function cutbackFbProxy(event_name: string, params: FacebookTrackingParams) {
    if(!trackingEnvironments.includes(ENVIRONMENT)) {
        // log to console and return
        console.log('FB Serve', event_name, params.event_id, params);
        return;
    }

    // post
    await post('tracking/fb', params);
}

export default async function fbEvent(
    event_name: string, 
    user, 
    event_id = v4(), 
    cookies?,
    custom_data?: CustomDataDto
) {
    if (!user) {
        dataDogLogger('info', `fbEvent: no user for event ${event_name}`);
        return;
    }

    const locale = Intl.DateTimeFormat().resolvedOptions().locale;
    const countryCode = localeCode.getCountryCode(locale);
    const country = countryCode;

    const user_data: FacebookTrackingUserData = {
        em: user.email,
        ph: user.phone,
        fn: user.givenName,
        ge: user.gender,
        external_id: user.id,
        country: country
    };

    // insert fpc and fbp if they exist
    if(cookies) {
        if(cookies['_fbc']) {
            user_data.fbc = cookies['_fbc'];
        }
        if(cookies['_fbp']) {
            user_data.fbp = cookies['_fbp'];
        }
    }

    // send via fb pixel
    // turn off fb pixel to check conversion api
    fbqProxy(event_name, user_data, event_id);

    // clone so we can hash data
    const server_user_data: FacebookTrackingUserData = {
        ...user_data
    };

    // hash required keys to send to server, required by FB:
    // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters
    ['em', 'ph', 'fn', 'ge', 'country'].forEach(key => {
        if(!server_user_data[key]) {
            return;
        }
        const data: string = server_user_data[key] as string;
        server_user_data[key] = createHash('sha256').update(data.toLowerCase()).digest('hex');
    });

    server_user_data.client_user_agent = window.navigator.userAgent,

    // send to our internal server
    await cutbackFbProxy(event_name, {
        event_name: event_name,
        event_id: event_id,
        event_time: Math.floor(Date.now() / 1000), // server expects seconds
        action_source: 'website',
        user_data: server_user_data,
        event_source_url: window.location.href,
        custom_data: custom_data
    });
}