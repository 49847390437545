import Analytics from 'analytics';
import amplitudePlugin from '@analytics/amplitude';
import { isOkToLoad } from '../utils/isOkToLoad';

const analytics = Analytics({
    app: 'sunnyside-amplitude',
    plugins: [    
        amplitudePlugin({
            apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_ID,
            // amplitude sdk options: https://amplitude.github.io/Amplitude-JavaScript/Options/
            options: {
                saveEvents: true,
                includeUtm: true,
                includeReferrer: true,
                includeGclid: true,
                includeFbclid: true,
                saveParamsReferrerOncePerSession: false
            },
            enabled: isOkToLoad,
        }) 
        
    ]
});

export default analytics;