export const variables = {
    maxWidth: '1440px !important',
    maxWidthMargin: '0 auto',
    desktopPadding: '5rem 5.5rem',
    phonePadding: '4rem 1.25rem 4rem 1.25rem',
    xsPadding: '4rem 1rem 4rem 1rem',
};

export const landerVariables = {
    maxWidth: '1440px !important',
    maxWidthMargin: '0 auto',
    desktopPadding: '2.5rem 2.75rem',
    phonePadding: '2rem 1.25rem',
    xsPadding: '2rem 1rem',
};

