import { Data, post } from '../networking';

export interface BrazeUserTrackPostPayload {
    attributes?: Array<{
        [attribute: string]: string
    }>,
    events?: Array<{
        name: string,
        time: string,
        [property: string]: string | boolean | number | any
    }>
}

/**
 * Only used for logged in users 'jwt' protected
 * @param payload
 */
export function brazeUserTrack(payload: BrazeUserTrackPostPayload) {
    return post('braze/user-track', payload as Data);
}

export function brazeSDK_changeUser(userId: string) {
    if (!window || !window['braze']) return;
    window['braze'].changeUser(userId);
}