import React from 'react';

const SunnysideWhiteLogo = ({
    xmlns='http://www.w3.org/2000/svg',
    height = 357,
    width = 1112,
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 1112 357" fill="none" xmlns={xmlns}>
        <path d="M679.198 2.43279e-06C632.126 0.00551954 585.993 13.168 546.005 38.0018C506.017 62.8356 473.767 98.3521 452.894 140.543C452.598 141.138 452.458 141.798 452.488 142.462C452.519 143.125 452.717 143.77 453.066 144.336C453.414 144.902 453.901 145.369 454.48 145.694C455.06 146.02 455.712 146.192 456.377 146.195H902.05C902.718 146.194 903.375 146.023 903.959 145.696C904.542 145.369 905.031 144.898 905.381 144.328C905.73 143.758 905.928 143.108 905.954 142.44C905.981 141.772 905.836 141.108 905.533 140.512C884.656 98.3203 852.399 62.8051 812.405 37.9762C772.411 13.1472 726.272 -0.00653117 679.198 2.43279e-06Z" fill="#FFE636"/>
        <path d="M54.8758 304.777C26.6756 304.777 4.31101 290.906 0.0641767 268.114C-0.047877 267.452 -0.0128796 266.772 0.166694 266.125C0.346267 265.477 0.666025 264.877 1.10331 264.367C1.54059 263.857 2.08471 263.449 2.69711 263.173C3.30952 262.896 3.97523 262.758 4.64709 262.767H25.2091C26.2101 262.779 27.1807 263.112 27.9782 263.717C28.7758 264.322 29.358 265.167 29.6393 266.128C33.1528 277.616 43.9074 281.954 55.548 281.954C67.983 281.954 76.5378 277.035 76.5378 268.206C76.5378 259.376 70.8549 254.487 57.3812 251.096L35.9942 245.413C13.6907 239.7 2.81392 225.187 2.81392 206.275C2.81392 184.491 22.0011 169.979 51.0262 169.979C76.7516 169.979 95.2666 183.116 99.6967 203.342C99.8279 204.012 99.8086 204.704 99.6403 205.366C99.4719 206.028 99.1586 206.644 98.723 207.17C98.2875 207.696 97.7405 208.119 97.1217 208.408C96.5028 208.697 95.8274 208.845 95.1444 208.842H75.6212C74.6767 208.835 73.7572 208.538 72.9868 207.991C72.2164 207.445 71.6322 206.675 71.3132 205.786C69.7454 201.734 66.9285 198.285 63.2703 195.939C59.6121 193.594 55.3024 192.474 50.9651 192.74C40.0883 192.74 32.0529 197.69 32.0529 205.97C32.0529 214.249 38.5301 218.68 49.4069 221.521L71.191 227.234C94.5333 233.345 105.655 246.666 105.655 266.373C105.655 291.579 83.901 304.777 54.8758 304.777Z" fill="white"/>
        <path d="M124.293 172.732H143.541C144.768 172.732 145.944 173.217 146.814 174.082C147.685 174.946 148.177 176.12 148.185 177.346V245.57C148.185 266.285 157.351 278.995 174.094 278.995C192.426 278.995 205.716 264.207 205.716 236.496V177.193C205.764 175.994 206.274 174.859 207.14 174.027C208.005 173.196 209.16 172.731 210.36 172.732H229.609C230.83 172.74 231.998 173.229 232.862 174.093C233.725 174.956 234.214 176.125 234.222 177.346V297.693C234.222 298.92 233.737 300.096 232.873 300.966C232.008 301.836 230.835 302.329 229.609 302.337H210.36C209.129 302.337 207.947 301.848 207.076 300.977C206.206 300.106 205.716 298.925 205.716 297.693C205.728 296.803 205.482 295.929 205.008 295.176C204.534 294.422 203.852 293.822 203.045 293.447C202.238 293.073 201.339 292.94 200.458 293.064C199.577 293.189 198.75 293.566 198.078 294.149C189.718 301.388 178.946 305.226 167.892 304.904C138.072 304.904 119.68 283.517 119.68 250.214V177.193C119.727 175.999 120.233 174.869 121.092 174.038C121.951 173.207 123.098 172.739 124.293 172.732Z" fill="white"/>
        <path d="M264.31 172.776H283.528C284.754 172.776 285.931 173.261 286.801 174.125C287.671 174.99 288.164 176.163 288.172 177.389V177.725C288.176 178.622 288.439 179.499 288.931 180.249C289.423 181 290.121 181.592 290.942 181.954C291.763 182.315 292.671 182.432 293.556 182.289C294.442 182.146 295.267 181.749 295.932 181.147C304.824 173.709 316.117 169.756 327.707 170.026C357.771 170.026 377.202 191.413 377.202 224.196V297.523C377.202 298.133 377.082 298.737 376.849 299.3C376.615 299.863 376.273 300.375 375.842 300.807C375.411 301.238 374.899 301.58 374.336 301.813C373.772 302.047 373.168 302.167 372.558 302.167H353.341C352.731 302.167 352.127 302.047 351.563 301.813C351 301.58 350.488 301.238 350.057 300.807C349.626 300.375 349.284 299.863 349.05 299.3C348.817 298.737 348.697 298.133 348.697 297.523V228.871C348.697 208.37 338.339 195.935 321.749 195.935C302.806 195.935 288.294 210.722 288.294 238.464V297.584C288.294 298.816 287.805 299.997 286.934 300.868C286.063 301.739 284.881 302.228 283.65 302.228H264.432C263.2 302.228 262.019 301.739 261.148 300.868C260.277 299.997 259.788 298.816 259.788 297.584V177.237C259.834 176.058 260.327 174.941 261.167 174.112C262.007 173.284 263.131 172.806 264.31 172.776Z" fill="white"/>
        <path d="M405.281 172.763H424.529C425.75 172.771 426.919 173.26 427.782 174.123C428.646 174.987 429.134 176.156 429.142 177.377V177.713C429.147 178.61 429.41 179.486 429.902 180.237C430.394 180.987 431.092 181.579 431.913 181.941C432.733 182.303 433.642 182.419 434.527 182.276C435.413 182.133 436.238 181.737 436.903 181.135C445.787 173.784 457.028 169.889 468.556 170.166C498.619 170.166 518.051 191.553 518.051 224.336V297.663C518.051 298.889 517.566 300.066 516.702 300.936C515.837 301.806 514.664 302.299 513.438 302.307H494.189C492.958 302.307 491.776 301.818 490.905 300.947C490.035 300.076 489.545 298.895 489.545 297.663V228.858C489.545 208.357 479.188 195.922 462.598 195.922C443.655 195.922 429.142 210.71 429.142 238.452V297.571C429.142 298.798 428.657 299.974 427.793 300.844C426.929 301.714 425.755 302.207 424.529 302.215H405.281C404.049 302.215 402.868 301.726 401.997 300.855C401.126 299.984 400.637 298.803 400.637 297.571V177.224C400.684 176.024 401.194 174.89 402.06 174.058C402.926 173.226 404.08 172.762 405.281 172.763Z" fill="white"/>
        <path d="M551.637 349.973L575.529 297.27C575.802 296.67 575.943 296.019 575.943 295.36C575.943 294.701 575.802 294.05 575.529 293.451L523.59 179.306C523.266 178.601 523.125 177.826 523.181 177.053C523.236 176.279 523.485 175.532 523.905 174.88C524.325 174.229 524.903 173.693 525.585 173.324C526.267 172.955 527.031 172.763 527.806 172.767H548.918C549.821 172.761 550.706 173.021 551.461 173.516C552.217 174.01 552.81 174.717 553.165 175.548L587.628 254.71C587.982 255.543 588.573 256.255 589.329 256.754C590.084 257.254 590.969 257.521 591.875 257.521C592.781 257.521 593.666 257.254 594.422 256.754C595.177 256.255 595.768 255.543 596.122 254.71L630.341 175.548C630.703 174.722 631.297 174.019 632.051 173.526C632.805 173.032 633.687 172.769 634.588 172.767H655.975C656.75 172.763 657.514 172.955 658.196 173.324C658.878 173.693 659.455 174.229 659.876 174.88C660.296 175.532 660.545 176.279 660.6 177.053C660.655 177.826 660.515 178.601 660.191 179.306L581.273 353.67C580.908 354.482 580.315 355.171 579.567 355.653C578.819 356.135 577.947 356.391 577.057 356.389H555.884C555.125 356.382 554.379 356.188 553.711 355.826C553.044 355.463 552.476 354.942 552.057 354.309C551.638 353.676 551.381 352.949 551.307 352.193C551.234 351.437 551.347 350.675 551.637 349.973Z" fill="white"/>
        <path d="M720.132 304.777C691.962 304.777 669.598 290.906 665.32 268.114C665.214 267.452 665.253 266.775 665.434 266.13C665.616 265.485 665.936 264.887 666.372 264.378C666.809 263.869 667.35 263.462 667.96 263.183C668.57 262.905 669.233 262.763 669.903 262.767H690.557C691.563 262.773 692.541 263.103 693.344 263.708C694.148 264.314 694.735 265.162 695.018 266.128C698.531 277.616 709.286 281.954 720.926 281.954C733.361 281.954 741.916 277.035 741.916 268.206C741.916 259.376 736.203 254.487 722.729 251.096L701.495 245.413C679.191 239.7 668.314 225.187 668.314 206.275C668.314 184.491 687.471 169.979 716.527 169.979C742.252 169.979 760.767 183.116 765.197 203.342C765.329 204.015 765.309 204.709 765.139 205.373C764.969 206.037 764.653 206.655 764.214 207.182C763.775 207.709 763.224 208.131 762.602 208.418C761.979 208.705 761.3 208.85 760.614 208.842H740.908C739.961 208.828 739.041 208.529 738.267 207.984C737.493 207.439 736.901 206.673 736.569 205.786C735.011 201.733 732.201 198.283 728.547 195.936C724.893 193.59 720.586 192.471 716.252 192.74C705.375 192.74 697.34 197.69 697.34 205.97C697.34 214.249 703.817 218.68 714.694 221.521L736.478 227.234C759.789 233.345 770.941 246.666 770.941 266.373C770.941 291.579 749.31 304.777 720.132 304.777Z" fill="white"/>
        <path d="M791.421 172.767H810.639C811.865 172.767 813.042 173.252 813.912 174.116C814.782 174.981 815.275 176.154 815.283 177.381V297.728C815.283 298.96 814.794 300.141 813.923 301.012C813.052 301.883 811.871 302.372 810.639 302.372H791.421C790.19 302.372 789.008 301.883 788.138 301.012C787.267 300.141 786.777 298.96 786.777 297.728V177.228C786.825 176.028 787.335 174.894 788.201 174.062C789.066 173.23 790.221 172.766 791.421 172.767Z" fill="white"/>
        <path d="M893.814 169.991C906.116 169.995 918.048 174.199 927.636 181.906C928.31 182.475 929.133 182.84 930.007 182.958C930.88 183.077 931.77 182.944 932.571 182.575C933.373 182.207 934.052 181.618 934.531 180.877C935.01 180.136 935.268 179.275 935.274 178.393V112.429C935.274 111.197 935.764 110.016 936.635 109.145C937.506 108.274 938.687 107.785 939.919 107.785H959.136C960.368 107.785 961.549 108.274 962.42 109.145C963.291 110.016 963.78 111.197 963.78 112.429V297.548C963.78 298.78 963.291 299.961 962.42 300.832C961.549 301.703 960.368 302.192 959.136 302.192H939.919C938.687 302.192 937.506 301.703 936.635 300.832C935.764 299.961 935.274 298.78 935.274 297.548V296.448C935.273 295.564 935.02 294.698 934.543 293.953C934.067 293.208 933.388 292.614 932.585 292.242C931.783 291.869 930.891 291.734 930.014 291.851C929.137 291.969 928.312 292.334 927.636 292.904C918.047 300.603 906.111 304.786 893.814 304.759C858.281 304.759 831.334 274.45 831.334 237.39C831.334 200.329 858.281 169.991 893.814 169.991ZM898.978 195.899C875.91 195.899 860.359 214.048 860.359 237.39C860.359 260.732 875.91 278.85 898.978 278.85C922.045 278.85 937.597 260.702 937.597 237.39C937.597 214.078 922.045 195.899 899.008 195.899H898.978Z" fill="white"/>
        <path d="M1046.76 304.781C1008.15 304.781 980.404 276.276 980.404 237.413C980.404 199.038 1007.11 170.013 1047.04 170.013C1085.26 170.013 1112.42 198.213 1112 239.735C1112 240.969 1111.51 242.153 1110.64 243.029C1109.77 243.905 1108.59 244.401 1107.35 244.409H1013.92C1013.23 244.409 1012.55 244.563 1011.92 244.859C1011.3 245.155 1010.74 245.586 1010.31 246.12C1009.87 246.655 1009.55 247.281 1009.39 247.952C1009.22 248.622 1009.2 249.322 1009.34 250C1012.67 266.957 1026.97 279.667 1047.8 279.667C1061.19 279.667 1072.98 274.626 1079.3 263.719C1079.71 263.034 1080.28 262.47 1080.98 262.083C1081.67 261.697 1082.45 261.502 1083.24 261.519H1103.75C1104.52 261.523 1105.28 261.721 1105.97 262.094C1106.65 262.467 1107.22 263.003 1107.64 263.655C1108.07 264.307 1108.32 265.054 1108.38 265.827C1108.44 266.601 1108.31 267.378 1107.99 268.088C1098.64 289.199 1076.65 304.781 1046.76 304.781ZM1015.75 224.183H1075.15C1075.88 224.176 1076.6 223.996 1077.25 223.658C1077.9 223.321 1078.46 222.835 1078.89 222.24C1079.32 221.646 1079.6 220.959 1079.72 220.235C1079.83 219.511 1079.77 218.77 1079.55 218.073C1077.39 210.964 1072.97 204.756 1066.96 200.393C1060.95 196.03 1053.67 193.752 1046.25 193.905C1030.3 193.905 1016.82 202.155 1011.32 218.103C1011.1 218.801 1011.05 219.541 1011.17 220.263C1011.29 220.986 1011.57 221.67 1012 222.262C1012.43 222.853 1013 223.335 1013.65 223.669C1014.3 224.003 1015.02 224.179 1015.75 224.183Z" fill="white"/>
    </svg>
);

export default SunnysideWhiteLogo;