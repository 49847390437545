import { post } from '../../networking';
import WellputClientEventDto from './types/wellput-client-event.dto';

const ENVIRONMENT = process.env.NEXT_PUBLIC_NENV;
const IS_PRODUCTION = ENVIRONMENT === 'production';

const wellputEvent = async (event: WellputClientEventDto) => {
    if (!IS_PRODUCTION) {
        console.log('Wellput event', { event });
        return;
    }
    await post('tracking/wellput', { ...event });
};

export default wellputEvent;
