export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const gaPageView = (url) => {
    if(!window['gtag']) {
        return;
    }
    window['gtag']('event', 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: url,
        send_to: GA_TRACKING_ID
    });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gaEvent = ({ action, category = null, label = null, value = null, send_to = GA_TRACKING_ID }) => {
    if(!window['gtag']) {
        return;
    }

    const domains = [
        'app.sunnyside.co',
        'sunnyside.co',
        'get.sunnyside.co'
    ];

    const payload = {
        event_category: category,
        event_label: label,
        value,
        send_to,
        domains
    };
    window['gtag']('event', action, payload);
};

export const gaObject = (data: any, send_to = GA_TRACKING_ID) => {
    if(!window['gtag']) {
        return;
    }

    const payload = {
        ...data,
        send_to
    };
    window['gtag'](payload);
};
