import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { colors } from './styles/_colors';

export const additionalColors = {
    purple: '#8C00FA',
    trackingRed: '#FF4C06'
};

// Used to preserve current dashboard styling but new color for sunnyside 'dual' mode
const theme_sunnyside_dual_dashboard = createTheme(({
    typography: {
        fontSize: 16,
        fontFamily: 'Poppins',
        body1: {
            lineHeight: '1.3',
            fontSize: '1rem',
        },
        body2: {
            fontSize: '0.875rem',
        },
        button: {
            textTransform: 'none',
            color: '#121C27',
            borderRadius: 8,
            fontSize: '1rem',
        },
        h1: {
            fontFamily: 'Domine',
            fontSize: '2em',
            lineHeight: '1.25',
            fontWeight: 700
        },
        h2: {
            fontFamily: 'Domine',
            fontSize: '1.5em',
            lineHeight: '1.5',
            fontWeight: 700
        },
        h3: {
            fontFamily: 'Domine',
            fontSize: '1.17em',
        },
        h4: {
            fontFamily: 'Domine',
            fontSize: '1em',
        },
        h5: {
            fontFamily: 'Domine'
        }
    },
    palette: {
        primary: {
            main: colors.sky,
            light: '#54EBD2'
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
        warning: {
            main: '#FFC008'
        },
        background: {
            default: '#fff',
        },
        success: {
            main: '#A5D04B'
        },
        info: {
            main: '#DCE3EA',
            light: '#EDF1F4',
            dark: '#414952'
        }
    },
    shape: {
        borderRadius: 10
    },
}));

export default theme_sunnyside_dual_dashboard;