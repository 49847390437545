import React, { Component, ErrorInfo, ReactNode } from 'react';
import Grid, { GridProps } from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { ampLogEvent } from '../../lib/amplitude';
import dataDogLogger from '../../lib/datadog';
import { colors } from '../../styles/_colors';
import Head from 'next/head';
import ErrorBoundaryContent from '../../sections/error/error_boundary_content';
import SunnysideHeader from '../header';
import SessionContext from '../../contexts/session';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error,
  errorInfo?: ErrorInfo,
}

class ErrorBoundary extends Component<Props, State> {
    static contextType = SessionContext;

    constructor(props) {
        super(props);
  
        // Define a state variable to track whether is an error or not
        this.state = { 
            hasError: false,
        };
    }
    static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI
  
        return { hasError: true };
    }
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can use your own error logging service here
        let payload: any = {
            clientError: error,
            clientErrorInfo: errorInfo,
            url: window.location.href,
        };
        if (this.context.user.id) {
            payload = {
                ...payload,
                userId: this.context.user.id
            };
        }
        dataDogLogger(
            'error',
            'error: client side exception logged',
            payload
        );
        ampLogEvent(
            'error: client side exception logged',
            payload
        );
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }
    ParentGrid = styled(Grid)<GridProps>(({ theme }) => ({
        background: colors.cloud,
        width: '100%',
    }));
    Main = styled('main')(({ theme }) => ({
        width: '100%',
    }));


    buttonOnClick = () => {
        document.location.reload();
        this.setState({ hasError: false });
    }
    
    render() {
        // Check if the error is thrown
        if (this.state.hasError) {
        // You can render any custom fallback UI
            return (
                <this.ParentGrid container columns={{ xs: 6, sm: 12 }} className='sunnyside'>
                    <Head>
                        <meta 
                            name="description" 
                            content="Mindful drinking is about getting more out of life, not less. Learn about our values, the science, our team, and the benefits our members experience." />
                        <link rel="canonical" href="https://www.sunnyside.co/about" />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:site" content="@joinsunnyside" />
                        <meta name="twitter:creator" content="@joinsunnyside" />
                    </Head>
                    <SunnysideHeader bgcolor={colors.cloud} noNav={true}/>
                    <this.Main>
                        <ErrorBoundaryContent />
                    </this.Main>
                </this.ParentGrid>
            );
        }
  
        // Return children components in case of no error
  
        return this.props.children;
    }
}
  
export default ErrorBoundary;