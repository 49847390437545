import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '../../styles/_colors';
import React from 'react';
import { useRouter } from 'next/router';

interface SunnysideButtonProps extends ButtonProps {
    bgcolor?: any,
    hovercolor?: any,
    textcolor?: any,
    width?: string,
    height?: string,
    margin?: string,
    children: string | JSX.Element | JSX.Element[],
    url?: string,
    borderRadius?: string,
    padding?: string,
    external?: boolean,
    fontSize?: string,
    fontWeight?: number,
    passQueryToUrl?: boolean
}
function SunnysideButton(props: SunnysideButtonProps, ref: React.Ref<any>): JSX.Element {
    const router = useRouter();
    const { 
        bgcolor, 
        hovercolor, 
        textcolor,  
        width, 
        height, 
        margin, 
        children, 
        url,
        borderRadius,
        padding,
        fontSize,
        fullWidth,
        fontWeight,
        passQueryToUrl
    } = props;
    const StyledButton = styled(Button, {
        shouldForwardProp: (prop) =>
            prop !== 'borderRadius' 
    })<ButtonProps>(({ theme }) => ({
        height: height || '60px',
        margin: margin || '0px',
        backgroundColor: bgcolor || colors.sky,
        color: `${textcolor || colors.cloud} !important`,
        boxShadow: 'none',
        borderRadius: borderRadius || '0',
        padding: padding,
        fontWeight: fontWeight || 600,
        fontSize: fontSize ? `${fontSize} !important` : 'initial',
        '&:hover': {
            backgroundColor: hovercolor || colors.sky_hover,
            boxShadow: 'none'
        },
        [theme.breakpoints.down(400)]: {
            width: fullWidth ? null : width || '310px',
        },
        [theme.breakpoints.up(400)]: {
            width: fullWidth ? null : width || '360px',
        },
    }));

    /* Probably a better way to ensure formatting is corrected but quick implementation */
    const getUrl = (): string => {
        if (!passQueryToUrl) return url;
        const query = router.query;
        if (!query) return url;
        /* Add query to url when passQueryToUrl is true */
        const urlContainsQuery = url.includes('?');
        return url + Object.keys(query).reduce((p, k, i) => {
            const value = query[k];
            const formattedValue = Array.isArray(value) ? JSON.stringify(value) : value;
            return `${p}${i === 0 && !urlContainsQuery ? '?' : '&'}${k}=${formattedValue}`;
        }, '');
    };

    return (
        url ?
            <StyledButton 
                variant="contained" 
                href={getUrl()}
                ref={ref}
                {...props}>{children}</StyledButton>
            :
            <StyledButton ref={ref} variant="contained" {...props}>{children}</StyledButton>
    );
}
export default React.forwardRef(SunnysideButton);