export const colors = {
    forest: '#052C17',
    pine: '#124A2C',
    pine_hover: '#1F844D',
    mint: '#BED6CC',
    sunny: '#FFE635',
    sunny_hover: '#F2D314',
    beige: '#FBF7E9',
    cloud: '#FFFFFF',
    cloud_hover: '#E6E6E6',
    grass: '#A5D04B',
    berry: '#F05030',
    coral: '#FF8B6D',
    dark_gray: '#333333',
    light_gray: '#828282',
    sky: '#5DC0FE',
    sky_hover:'#15A5FF',
    mist: '#B6E4FC',
    black: '#000000',
    green: '#16D79D',
    purple: '#8C00FA',
    purple_light: '#B05DF1',
    dark_gold: '#6F674F',
    brown: '#000000'
};