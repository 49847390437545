import { post } from '../networking';

export async function getMyCustomer() {
    return await post('payments-gateway/stripe/my-customer');
}

export enum SubscriptionState {
    NO_SUBSCRIPTION,
    NO_CREDIT_CARD_TRIAL,
    CREDIT_CARD_TRIAL,
    MS_NO_SUBSCRIPTION,
    ACTIVE,
    ACTIVE_WITH_PAYMENT_ERROR,
    CANCELED,
    CHURNED_FROM_PAID,
    LAPSED,
    UNKNOWN,
    ACTIVE_DO_NOT_RENEW
}

export function interpretStripeState(user, subscription?, payment?, customer?): SubscriptionState {
    if(!user) {
        return SubscriptionState.UNKNOWN;
    }

    if (subscription && subscription.plan.active && subscription.cancel_at_period_end) {
        return SubscriptionState.ACTIVE_DO_NOT_RENEW;
    }

    if(payment && !subscription) {
        return SubscriptionState.CREDIT_CARD_TRIAL;
    }

    if(!subscription) {
        if (customer && customer.metadata && customer.metadata.ms_member) {
            return SubscriptionState.MS_NO_SUBSCRIPTION;
        }
        return SubscriptionState.NO_SUBSCRIPTION;
    }

    if(subscription.status === 'trialing' && !subscription.default_payment_method) {
        return SubscriptionState.NO_CREDIT_CARD_TRIAL;
    } else if (subscription.status === 'trialing' && subscription.default_payment_method) {
        return SubscriptionState.CREDIT_CARD_TRIAL;
    } else if (['incomplete', 'incomplete_expired', 'past_due', 'unpaid'].includes(subscription.status)) {
        return SubscriptionState.ACTIVE_WITH_PAYMENT_ERROR;
    } else if (['canceled'].includes(subscription.status)) {
        return SubscriptionState.CANCELED;
    } else if (subscription.plan && subscription.plan.active) {
        return SubscriptionState.ACTIVE;
    }
    return SubscriptionState.UNKNOWN;
}