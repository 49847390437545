import queryString from 'query-string';
import { v4 } from 'uuid';
import { AppUserAgent } from '../components/core/use-app-user-agent';
import { ampLogEvent } from './amplitude';
import fbEvent from './facebook';
import { gaEvent } from './gtag';
import tikTokEvent from './tik-tok';
import TikTokEvent from './tik-tok/types/tik-tok-event.enum';
import snapchatEvent from './snapchat';
import SnapchatEvent from './snapchat/types/snapchat-event.enum';
import wellputEvent from './wellput';
import WellputEventGoal from './wellput/types/wellput-event-goal.enum';

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const urlQueryParser = (path: string) => queryString.parse(path.split(/\?/)[1]);

/**
 *
 * @param user
 * @param cookies
 * @param path is derived from router.asPath
 */
export const ccTrialTrack = (user, cookies?, path?: string) => {
    /* Path returns with '/' and API_HOST ends with '/' so we remove the path one */
    const url = `${process.env.NEXT_PUBLIC_API_HOST}${path.slice(1)}`;
    const event_id = v4();
    fbEvent('StartTrial', user, event_id, cookies);
    fbEvent('Purchase', user, event_id, cookies, { currency: 'USD', value: 99.99 });
    /* Google analytics */
    gaEvent({
        action: 'cc_trial_start'
    });
    /* Google ads */
    gaEvent({
        action: 'conversion',
        send_to: 'AW-644430336/fya-CNSGucMDEID0pLMC'
    });
    ampLogEvent(
        'signup: start trial fb event',
        {},
        cookies
    );
    tikTokEvent(user, {
        event: TikTokEvent.PLACE_AN_ORDER,
        url
    });
    tikTokEvent(user, {
        event: TikTokEvent.COMPLETE_PAYMENT,
        url
    });
    snapchatEvent(SnapchatEvent.PURCHASE, url);
    wellputEvent({
        goal: WellputEventGoal.SALE
    });
};

export const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase().trim());
};

export const getAppEventSource = (
    cookies: {[name: string]: any} | undefined, userAgent: AppUserAgent | undefined
): 'ios' | 'web' | 'android' => {
    if (cookies?.ss_app === 'true') {
        return 'ios';
    }
    return userAgent?.os ? userAgent.os : 'web';
};

export const getEventMetaData = (cookies?: {[name: string]: any} | undefined): { event_source: string, event_device: string } => {
    let event_source = 'unknown';
    let event_device = 'unknown';

    let rawUserAgent = '';
    if (typeof window !== 'undefined') rawUserAgent = window?.navigator?.userAgent?.toLowerCase();

    if (cookies?.ss_app === 'true') {
        event_source = cookies?.ss_app_device;
        if (rawUserAgent.includes('ipad')) {
            event_device = 'ipad';
        } else if (cookies?.ss_app_device === 'ios') {
            event_device = 'iphone';
        } else if (cookies?.ss_app_device === 'android') {
            event_device = 'android';
        }
    } else {
        if (rawUserAgent.includes('ipad')) {
            event_source = rawUserAgent.includes('sunnyside') ? 'ios' : 'web';
            event_device = 'ipad';
        } else if (rawUserAgent.includes('iphone')) {
            event_source = rawUserAgent.includes('sunnyside') ? 'ios' : 'web';
            event_device = 'iphone';
        } else if (rawUserAgent.includes('android')) {
            event_source = rawUserAgent.includes('sunnyside') ? 'android' : 'web';
            event_device = 'android';
        } else if (rawUserAgent.includes('linux') || rawUserAgent.includes('windows') || rawUserAgent.includes('mac os')) {
            event_source = 'web';
            event_device = 'computer';
        }
    }

    return {
        event_source,
        event_device
    };
};