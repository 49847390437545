import {
    Menu,
    Box,
    Button,
    ButtonProps,
    MenuItem, 
    IconButton } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
import CloseIcon from '@mui/icons-material/Close';
import React, { useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { colors } from '../../styles/_colors';
import SunnysideLink from '../link';

interface SelectorProps {
    options: string[],
    optionLinks?: string[],
    icon?: boolean,
    iconColor?: any,
    menuWidth?: any,
    onClick?: any,
    selectorIndex?: any,
}

export default function SunnysideSelector(props: SelectorProps): JSX.Element {
    const { options, optionLinks,  icon, iconColor, menuWidth, onClick, selectorIndex } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const divRef = useRef();

    const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
        background: colors.beige,
        color: colors.pine,
        height: 51,
        '&:hover': {
            background: colors.beige,
            color: colors.pine
        }
    }));

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        onClick && onClick(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = () => {
        setAnchorEl(divRef.current);
    };

    return (
        <Box marginTop='0.5rem'
            marginBottom='0.5rem'
            width={icon ? 'fit-content' : '100%'}
            ref={divRef}>
            {icon ? 
                <IconButton 
                    aria-label="open side column" 
                    size="small" 
                    onClick={open ? handleClose : handleClick}
                    sx={{ color: iconColor || colors.pine}}>
                    {open ? <CloseIcon fontSize='large' /> :
                        <DehazeIcon fontSize="large"/>
                    }
                </IconButton>
                :
                <StyledButton
                    id="selector-button"
                    aria-controls="sunnyside-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    fullWidth
                >
                    {(selectorIndex === undefined) || (selectorIndex === -1) ? 'Categories' : options[selectorIndex]}
                </StyledButton>
            }
            <Menu
                id='nav-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
                PaperProps={{  
                    style: {  
                        maxWidth: '100%',
                        width: menuWidth, 
                    },  
                }}
            >
                {options.map((option, index) => (
                    icon ? 
                        <SunnysideLink href={optionLinks[index]} key={index}>
                            <MenuItem
                                divider={index !== (options.length - 1)}
                                sx={{ color: colors.pine }}
                                key={option}
                                selected={index === selectorIndex}
                                onClick={(event) => handleMenuItemClick(event, index)}
                            >
                                {option}
                            </MenuItem>
                        </SunnysideLink>
                        :
                        <MenuItem
                            key={option}
                            selected={index === selectorIndex}
                            onClick={(event) => handleMenuItemClick(event, index)}
                        >
                            {option}
                        </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}