import { post } from '../../networking';
import SnapchatClientWebEventDto from './types/snapchat-client-event.dto';
import SnapchatEventConversionType from './types/snapchat-event-conversion-type.enum';
import SnapchatEvent from './types/snapchat-event.enum';

const ENVIRONMENT = process.env.NEXT_PUBLIC_NENV;
const IS_PRODUCTION = ENVIRONMENT === 'production';

const uuidv4 = (): string => {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
};

const createEventId = (event: SnapchatEvent): string => {
    const uuid = uuidv4();
    return `${uuid}-${event}-${Date.now()}`;
};

const snapchatEvent = async (event: SnapchatEvent, url: string) => {
    if (!IS_PRODUCTION) {
        console.log('Snapchat event', { event });
        return;
    }
    const snaptr = window && window['snaptr'];
    const dedupId = createEventId(event);

    if (snaptr) {
        snaptr('track', event, {
            client_dedup_id: dedupId
        });
    }

    const pixelId = process.env.NEXT_PUBLIC_SNAPCHAT_PIXEL_ID;
    const payload: SnapchatClientWebEventDto = {
        type: 'web',
        pixel_id: pixelId,
        event_type: event,
        event_conversion_type: SnapchatEventConversionType.WEB,
        timestamp: Date.now(),
        page_url: url,
        client_dedup_id: dedupId
    };
    await post('tracking/snapchat', payload);
};

export default snapchatEvent;
