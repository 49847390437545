import Stripe from '../lib/stripe';
import { post } from '../networking';

export enum SubscriptionState {
    NO_SUBSCRIPTION,
    NO_CREDIT_CARD_TRIAL,
    CREDIT_CARD_TRIAL,
    MS_NO_SUBSCRIPTION,
    ACTIVE,
    ACTIVE_WITH_PAYMENT_ERROR,
    CANCELED,
    CHURNED_FROM_PAID,
    LAPSED,
    UNKNOWN,
    ACTIVE_DO_NOT_RENEW
}

export function interpretUserPaymentEntityState(user, userPaymentEntity): SubscriptionState {
    if(!user || !userPaymentEntity) {
        return SubscriptionState.UNKNOWN;
    }

    if (userPaymentEntity && userPaymentEntity.subscriptionStatus === 'active' && userPaymentEntity.subscriptionCancelAtPeriodEnd) {
        return SubscriptionState.ACTIVE_DO_NOT_RENEW;
    }

    if(userPaymentEntity.paymentMethodStatus === 'added' && userPaymentEntity.subscriptionStatus === 'trialing') {
        return SubscriptionState.CREDIT_CARD_TRIAL;
    }

    if(!userPaymentEntity) {
        return SubscriptionState.NO_SUBSCRIPTION;
    }

    if(userPaymentEntity.subscriptionStatus === 'trialing' && userPaymentEntity.paymentMethodStatus !== 'added') {
        return SubscriptionState.NO_CREDIT_CARD_TRIAL;
    } else if (userPaymentEntity.subscriptionStatus === 'trialing' && userPaymentEntity.paymentMethodStatus === 'added') {
        return SubscriptionState.CREDIT_CARD_TRIAL;
    } else if (['incomplete', 'incomplete_expired', 'past_due', 'unpaid'].includes(userPaymentEntity.subscriptionStatus)) {
        return SubscriptionState.ACTIVE_WITH_PAYMENT_ERROR;
    } else if (['canceled'].includes(userPaymentEntity.subscriptionStatus)) {
        return SubscriptionState.CANCELED;
    } else if (userPaymentEntity.subscriptionStatus === 'active') {
        return SubscriptionState.ACTIVE;
    }
    return SubscriptionState.UNKNOWN;
}