import { createMedia } from '@artsy/fresnel';

const SunnysideMedia = createMedia({
    breakpoints: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
    },
});

// Make styles for injection into the header of the page
export const mediaStyles = SunnysideMedia.createMediaStyle();

export const { Media, MediaContextProvider } = SunnysideMedia;