enum TikTokEvent {
    /* Standard events */
    VIEW_CONTENT = 'ViewContent',
    CLICK_BUTTON = 'ClickButton',
    SEARCH = 'Search',
    ADD_TO_WISHLIST = 'AddToWishlist',
    ADD_TO_CART = 'AddToCart',
    INITIATE_CHECKOUT = 'InitiateCheckout',
    ADD_PAYMENT_INFO = 'AddPaymentInfo',
    COMPLETE_PAYMENT = 'CompletePayment',
    PLACE_AN_ORDER = 'PlaceAnOrder',
    CONTACT = 'Contact',
    DOWNLOAD = 'Download',
    SUBMIT_FORM = 'SubmitForm',
    COMPLETE_REGISTRATION = 'CompleteRegistration',
    SUBSCRIBE = 'Subscribe'
    /* Custom events */
}

export default TikTokEvent;
