import React from 'react';

const SessionContext = React.createContext({
    user: {
        id: '',
        email: '',
        phone: '',
        givenName: '',
        gender: '',
        phoneAuthorized: '',
        createdAt: '',
        timeZone: '',
        __featureAccess__: [],
        __statesUntyped__: [],
        stripe: {
            currency: ''
        },
        flowType: null,
        diagnosticInFlight: true,
        diagnosticInFlightWithSub: false,
        formId: '',
        hashId: '',
        __challenges__: [],
        __states__: [],
        userCommunityInfo: {
            isCoach: false
        },
        userPaymentEntity: <any>{},
        abtest_bucket: 0,
        create_source: 'unknown',
        __roles__: []
    },
    setUser: (userData) => void(userData),
    ui: {
        hideNav: false,
    },
    setUI: (uiData) => void(uiData),
    initialized: false
});

export const SessionProvider = SessionContext.Provider;

/**
 * React context that helps us managing user Session
 */
export default SessionContext;