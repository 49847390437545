import { datadogLogs } from '@datadog/browser-logs';
import { ErrorInfo } from 'react';

/**
 * NOTE: Datadog only allows for 256 unique keys, please add useful keys only
 * Do not use these reserved keys: message, context, level, stack
 */
export type DataDogPayload = {
    fbc?: string,
    fbclid?: string,
    fbclidParam?: string,
    fbclidTime?: string,
    magicLink?: string,
    stripeCustomerId?: string,
    stripeEventId?: string,
    stripeEventType?: string,
    stripeInvoiceId?: string,
    stripeInvoiceStatus?: string,
    stripeEndpoint?: string,
    subscriptionId?: string,
    subscriptionStatus?: string,
    userId?: string,
    userEmail?: string,
    userHashId?: string,
    webhookProcess?: string,
    requestBrazeExportBody?: string,
    service?: 'STRIPE' | 'BRAZE',
    frontendMetric?: number,
    process?: string,
    clientError?: Error,
    clientErrorInfo?: ErrorInfo,
    url?: string,
    rawData?: any
};

export default function dataDogLogger(
    type: 'info' | 'debug' | 'warn' | 'error',
    message: string,
    context: DataDogPayload = {}
): void {
    if (typeof window === 'undefined' || !window['DD_LOGS']) {
        return;
    }
    context.userId = context.userId || localStorage.getItem('userId');
    datadogLogs.logger[type](message, context);
}