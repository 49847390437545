import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import SunnysideGrid from '../../../components_sunnyside/grid';
import SunnysideLink from '../../../components_sunnyside/link';
import { colors } from '../../../styles/_colors';
import useWindowSize from '../../../utils/useWindowSize';
import SunnysideButton from '../../../components_sunnyside/button';

export default function ErrorBoundaryContent(): JSX.Element {
    const { width } = useWindowSize();
    const Title = styled('h1')(({ theme }) => ({
        color: '#C1C1C1',
        fontFamily: 'Good Sans Regular',
        fontSize: '0.875rem',
        lineHeight: '1.875rem',
        fontWeight: 700,
        letterSpacing: '0.08em',
        [theme.breakpoints.down('lg')]: {
            paddingBottom: '2.5rem',
            textAlign: 'center'
        },
    }));
    
    return (
        <SunnysideGrid 
            maxwidthgrid
            display='flex'
            flexDirection='column'
            alignItems='center'>
            <Title>
                Client Side Error
            </Title>
            <Typography variant='h2'
                paddingBottom='2rem'
                textAlign='center'>
                We&apos;re sorry. Something seems to have gone wrong. 
                Usually refreshing the page fixes it.
            </Typography>
            <SunnysideButton 
                onClick={() => window && window.location.reload()}
                margin='1.5rem 0rem'>
                Refresh the page now
            </SunnysideButton>
            <Typography variant='body2'
                textAlign='center'
                color={colors.pine}
                width={width > 900 ? '38rem' : '100%'}>
                If that didn&apos;t work, please email us at&nbsp;
                <SunnysideLink 
                    href='mailto:support@sunnyside.co'
                    fontVariant='body2'
                    fontFamily='Good Sans Light'>
                    support@sunnyside.co
                </SunnysideLink>
                &nbsp;and we&apos;ll be sure to help get this resolved.
            </Typography>
            <Box height='5rem' />
        </SunnysideGrid>
    );
}