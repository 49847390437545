enum SnapchatEvent {
    PURCHASE = 'PURCHASE',
    SAVE = 'SAVE',
    START_CHECKOUT = 'CHECKOUT',
    ADD_CART = 'ADD_CART',
    VIEW_CONTENT = 'VIEW_CONTENT',
    ADD_BILLING = 'ADD_BILLING',
    SIGN_UP = 'SIGN_UP',
    SEARCH = 'SEARCH',
    PAGE_VIEW = 'PAGE_VIEW',
    SUBSCRIBE = 'SUBSCRIBE',
    AD_CLICK = 'AD_CLICK',
    AD_VIEW = 'AD_VIEW',
    COMPLETE_TUTORIAL = 'COMPLETE_TUTORIAL',
    LEVEL_COMPLETE = 'LEVEL_COMPLETE',
    INVITE = 'INVITE',
    LOGIN = 'LOGIN',
    SHARE = 'SHARE',
    RESERVE = 'RESERVE',
    ACHIEVEMENT_UNLOCKED = 'ACHIEVEMENT_UNLOCKED',
    ADD_TO_WISHLIST = 'ADD_TO_WISHLIST',
    SPENT_CREDITS = 'SPENT_CREDITS',
    RATE = 'RATE',
    START_TRIAL = 'START_TRIAL',
    LIST_VIEW = 'LIST_VIEW',
    APP_INSTALL = 'APP_INSTALL',
    APP_OPEN = 'APP_OPEN',
    CUSTOM_EVENT_1 = 'CUSTOM_EVENT_1',
    CUSTOM_EVENT_2 = 'CUSTOM_EVENT_2',
    CUSTOM_EVENT_3 = 'CUSTOM_EVENT_3',
    CUSTOM_EVENT_4 = 'CUSTOM_EVENT_4',
    CUSTOM_EVENT_5 = 'CUSTOMER_EVENT_5'
}

export default SnapchatEvent;
