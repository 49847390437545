import { styled } from '@mui/material/styles';
import Link from 'next/link';
import { colors } from '../../styles/_colors';

interface SunnysideLinkProps {
    color?: any,
    href: string,
    children: any
    textAlign?: any,
    height?: any,
    fontFamily?: any,
    fontVariant?: string,
    padding?: any,
    underline?: any,
    bold?: any,
    className?: any,
    onClick?: any,
    width?: any,
    external?: any,
    target?: any,
}
export default function SunnysideLink(props: SunnysideLinkProps): JSX.Element {
    const { 
        color, 
        href, 
        children, 
        textAlign, 
        height, 
        fontFamily, 
        fontVariant,
        padding,
        underline,
        bold,
        className,
        onClick,
        width,
        external,
    } = props;
    const StyledLink = styled('a', {
        shouldForwardProp: (prop) =>
            prop !== 'underline' 
            && prop !== 'external' 
            && prop !== 'textAlign' 
    })(({ theme }) => ({
        '&&&': {
            color: color || colors.pine,
        },
        width: width,
        fontFamily: fontFamily || 'Good Sans Regular',
        fontStyle: 'normal',
        fontWeight: bold ? 700 : 400,
        textAlign: textAlign || 'left',
        height: height || 'initial',
        padding: padding || '0.5rem 0rem',
        textDecoration: (underline && 'underline') || 'none',
        overflowWrap: 'break-word',
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography[fontVariant || 'link'] ? 
                theme.typography[fontVariant || 'link'][theme.breakpoints.down('sm')].fontSize
                : '1rem',
            lineHeight: theme.typography[fontVariant || 'link'] ? 
                theme.typography[fontVariant || 'link'][theme.breakpoints.down('sm')].lineHeight
                : '1.25rem'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography[fontVariant || 'link'] ? 
                theme.typography[fontVariant || 'link'][theme.breakpoints.up('sm')].fontSize
                : '1.125rem',
            lineHeight: theme.typography[fontVariant || 'link'] ? 
                theme.typography[fontVariant || 'link'][theme.breakpoints.up('sm')].lineHeight
                : '1.75rem'
        },
        '&:hover': {
            textDecoration: 'underline',
            textDecorationThickness: '2px',
        },
        '&.active-nav-link': {
            textDecoration: 'underline !important',
            textDecorationThickness: '2px',
        },
    }));

    return (
        external ? 
            <StyledLink 
                href={href} 
                className={className} 
                onClick={onClick} 
                target='_blank' 
                rel="noopener noreferrer" 
                {...props}>
                {children}
            </StyledLink>
            :
            <Link 
                href={href} 
                passHref 
                prefetch={false} 
                {...props}>
                <StyledLink 
                    className={className} 
                    onClick={onClick} 
                    {...props}>
                    {children}
                </StyledLink>
            </Link>
    );
}