import { createTheme } from '@mui/material/styles';
import React from 'react';
import { colors } from './styles/_colors';

declare module '@mui/material/styles' {
    interface TypographyVariants {
      blog?: React.CSSProperties;
      link?: React.CSSProperties;
      microcopy?: React.CSSProperties;
      smallcaps?: React.CSSProperties;
      smallcaps_bold?: React.CSSProperties;
      quote?: React.CSSProperties;
      feature_body?: React.CSSProperties;
      thinner_h2?: React.CSSProperties;
      terms_article_header?: React.CSSProperties;
      terms_article_small_header?: React.CSSProperties;
      terms_body?: React.CSSProperties;
      footer_text?: React.CSSProperties;
      footer_terms_text?: React.CSSProperties;
      team_name?: React.CSSProperties;
      blog_author?: React.CSSProperties;
    }
  
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
      blog?: React.CSSProperties;
      link?: React.CSSProperties;
      microcopy?: React.CSSProperties;
      smallcaps?: React.CSSProperties;
      smallcaps_bold?: React.CSSProperties;
      quote?: React.CSSProperties;
      feature_body?: React.CSSProperties;
      thinner_h2?: React.CSSProperties;
      terms_article_header?: React.CSSProperties;
      terms_article_small_header?: React.CSSProperties;
      terms_body?: React.CSSProperties;
      footer_heading?: React.CSSProperties;
      footer_text?: React.CSSProperties;
      footer_terms_text?: React.CSSProperties;
      team_name?: React.CSSProperties;
      blog_author?: React.CSSProperties;
    }
  }

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    blog: true;
    link: true;
    microcopy: true;
    smallcaps: true;
    smallcaps_bold: true;
    quote: true;
    feature_body: true;
    thinner_h2: true;
    terms_article_header: true;
    terms_article_small_header: true;
    terms_body: true;
    footer_heading: true;
    footer_text: true;
    footer_terms_text: true;
    team_name: true;
    blog_author: true;
  }
}

const theme = createTheme();
const theme_sunnyside = createTheme(({
    typography: {
        h1: {
            fontFamily: 'Recoleta Medium',
            fontWeight: 400,
            fontStyle: 'normal',
            letterSpacing: '-0.02em',
            color: colors.pine,
            [theme.breakpoints.down('sm')]: {
                fontSize: '3.125rem',
                lineHeight: '3.125rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '4rem',
                lineHeight: '4.125rem',
            },
        },
        h2: {
            fontFamily: 'Recoleta Medium',
            fontWeight: 400,
            fontStyle: 'normal',
            letterSpacing: '-0.01em',
            color: colors.pine,
            [theme.breakpoints.down('sm')]: {
                fontSize: '2rem',
                lineHeight: '2rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '3rem',
                lineHeight: '3.25rem',
            },
        },
        h3: {
            fontFamily: 'Recoleta Medium',
            fontWeight: 400,
            fontStyle: 'normal',
            color: colors.pine,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.375rem',
                lineHeight: '1.5rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.75rem',
                lineHeight: '2.25rem',
                letterSpacing: '-0.01em',
            },
        },
        h4: {
            fontFamily: 'Recoleta Medium',
            fontWeight: 400,
            fontStyle: 'normal',
            color: colors.pine,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.25rem',
                lineHeight: '1.375rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.75rem',
                lineHeight: '2rem',
            },
        },
        h5: {
            fontFamily: 'Good Sans Medium',
            fontWeight: 400,
            fontStyle: 'normal',
            color: colors.pine,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
                lineHeight: '1.375rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.5rem',
                lineHeight: '2rem',
            },
        },
        button: {
            fontFamily: 'Good Sans Medium',
            fontStyle: 'normal',
            color: colors.black,
            textTransform: 'none',
            [theme.breakpoints.down(400)]: {
                fontSize: '0.8rem',
                lineHeight: '1rem',
            },
            [theme.breakpoints.up(400)]: {
                fontSize: '1rem',
                lineHeight: '1.25rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.125rem',
                lineHeight: '1.375rem',
            },
        },
        body1: {
            fontFamily: 'Good Sans Light',
            fontWeight: 400,
            fontStyle: 'normal',
            color: colors.black,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
                lineHeight: '1.375rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.125rem',
                lineHeight: '2rem',
            },
        },
        body2: {
            fontFamily: 'Good Sans Light',
            fontWeight: 400,
            fontStyle: 'normal',
            color: colors.black,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem',
                lineHeight: '1.25rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.25rem',
                lineHeight: '2rem',
            },
        },
        blog: {
            fontFamily: 'Good Sans Light',
            fontWeight: 400,
            fontStyle: 'normal',
            color: colors.dark_gray,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem',
                lineHeight: '1.25rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.1rem',
                lineHeight: '1.7rem',
            },
        },
        link: {
            fontFamily: 'Good Sans Medium',
            fontWeight: 400,
            fontStyle: 'normal',
            color: colors.black,
            textDecoration: 'underline',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem',
                lineHeight: '1.25rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
            },
        },
        microcopy: {
            fontFamily: 'Good Sans Regular',
            fontWeight: 400,
            fontStyle: 'normal',
            color: colors.black,
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.75rem',
                lineHeight: '0.8125rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1rem',
                lineHeight: '1.25rem',
            },
        },
        smallcaps: {
            fontFamily: 'Good Sans Regular',
            fontWeight: 400,
            fontStyle: 'normal',
            color: colors.black,
            textTransform: 'uppercase',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.75rem',
                lineHeight: '0.8125rem',
                letterSpacing: '0.15em'
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1rem',
                lineHeight: '1.25rem',
                letterSpacing: '0.2em'
            },
        },
        smallcaps_bold: {
            fontFamily: 'Good Sans Bold',
            fontWeight: 700,
            fontStyle: 'normal',
            color: colors.black,
            textTransform: 'uppercase',
            fontSize: '0.75rem',
            lineHeight: '1rem',
            letterSpacing: '0.03em'
        },
        quote: {
            fontFamily: 'Recoleta Regular',
            fontWeight: 400,
            fontStyle: 'normal',
            color: colors.black,
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.5rem',
                lineHeight: '1.6rem',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.25rem',
                lineHeight: '1.5rem',
            }
        },
        feature_body: {
            fontFamily: 'Good Sans Light',
            fontWeight: 400,
            fontStyle: 'normal',
            color: colors.black,
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.75rem',
                lineHeight: '1rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.125rem',
                lineHeight: '1.5rem',
            },
        },
        thinner_h2: {
            fontFamily: 'Good Sans Light',
            color: colors.pine,
            textTransform: 'uppercase',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.75rem',
                lineHeight: '0.825rem',
                letterSpacing: '0.2em',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1rem',
                lineHeight: '1.25rem',
                letterSpacing: '0.17em'
            },
        },
        terms_article_header: {
            fontFamily: 'Good Sans Regular',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '1.5rem',
            lineHeight: '1.75rem',
            letterSpacing: '-0.01em',
            color: colors.dark_gray,
            padding: '2rem 0rem 1rem 0rem'
        },
        terms_article_small_header: {
            fontFamily: 'Good Sans Regular',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '1.125rem',
            lineHeight: '1.75rem',
            letterSpacing: '-0.01em',
            color: colors.dark_gray,
            paddingTop: '1rem'
        },
        terms_body: {
            fontFamily: 'Good Sans Regular',
            fontWeight: 'normal',
            letterSpacing: '-0.01em',
            color: colors.dark_gray,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
            },
        },
        footer_heading: {
            fontFamily: 'Good Sans Regular',
            fontWeight: 700,
            fontStyle: 'normal',
            fontSize: '0.875rem',
            lineHeight: '2.25rem',
            color: colors.pine
        },
        footer_text: {
            fontFamily: 'Good Sans Regular',
            fontWeight: 400,
            fontStyle: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
                lineHeight: '2.25rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.875rem',
                lineHeight: '2.25rem',
            },
            color: colors.dark_gray,
        },
        footer_terms_text: {
            fontFamily: 'Good Sans Light',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontSize: '0.625rem',
            lineHeight: '1.25rem',
            color: colors.dark_gray,
        },
        team_name: {
            fontFamily: 'Recoleta Medium',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontSize: '1.5rem',
            lineHeight: '2.25rem',
            textAlign: 'center',
            color: colors.pine,
        },
        blog_author: {
            fontFamily: 'Recoleta Medium',
            fontWeight: 400,
            fontStyle: 'normal',
            color: colors.pine,
            paddingRight: '0.5rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.375rem',
                lineHeight: '1.5rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.75rem',
                lineHeight: '2.25rem',
                letterSpacing: '-0.01em',
            },
        }
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    subtitle1: 'h6',
                    subtitle2: 'h6',
                    body1: 'p',
                    body2: 'p',
                    inherit: 'p',
                    blog: 'p',
                    link: 'a',
                    microcopy: 'p',
                    smallcaps: 'p',
                    smallcaps_bold: 'p',
                    quote: 'p',
                    feature_body: 'p',
                    thinner_h2: 'h2',
                    terms_article_header: 'h2',
                    terms_article_small_header: 'h3',
                    terms_body: 'p',
                    footer_heading: 'p',
                    footer_text: 'p',
                    footer_terms_text: 'p',
                    team_name: 'p',
                    blog_author: 'p'
                }
            }
        }
    },
    palette: {
        primary: {
            light: colors.pine,
            main: colors.sky,
            contrastText: colors.cloud
        },
        secondary: {
            main: colors.sunny,
        },
        background: {
            default: colors.cloud,
        },
        text: {
            primary: colors.black,
            secondary: colors.dark_gray,
            disabled: colors.light_gray
        },
        common: {
            black: colors.black,
            white: colors.cloud
        }
    },
    shape: {
        borderRadius: 10
    }
}));

export default theme_sunnyside;