import * as networking from './networking';
import { GetServerSidePropsContext } from 'next';
import * as builder from 'fetch-retry-ts';
import dataDogLogger from './lib/datadog';
import { datadogLogs } from '@datadog/browser-logs';

const fetchWithRetry = builder.default(fetch, {
    retries: 4,
    retryOn: [429, 503, 504],
    retryDelay: (attempt: number, error: Error | null, response: Response | null): number => {
        return Math.pow(2, attempt) * 500;
    }
});

const logFetchTiming = (message: string, time: number, path: string) => {
    dataDogLogger('info', `${message} -> loaded in ${time} ms`, {
        frontendMetric: time,
        process: path
    });
};

const responseToJson = async (response: Response, path?: string) => {
    const contentType = response.headers.get('content-type');
    const data = contentType && contentType.indexOf('application/json') > -1 ? await response.json() : {};
    dataDogLogger(
        'info',
        `[networking] ${path ?? response.url} ${response.status}`,
        { rawData: data }
    );
    return data;
};

/**
 * Utility to generate URL based on apiResource and
 * @param apiResource - resource name. If "foo", then "api.cutbackcoach.com/foo" is called.
 * @param context - GetServerSidePropsContext if called from SSR, null if called from client side
 */
function generateUrl(apiResource: string, params?: Record<string, string>): URL {
    const host = process.env.NEXT_PUBLIC_SUNNYSIDE_API_HOST;
    const url = new URL(host + apiResource);
    if (params) {
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    }
    return url;
}

export type Data = Record<string, string | unknown | number>;

/**
 * Utility to parse ServerSideProps context for SSR
 * @param context GetServerSidePropsContext for nextjs SSR
 */
export function parseFetchOptionFrom(method: string, context?: GetServerSidePropsContext, payload?: Record<string, unknown> | any[]): Record<string, unknown> {
    const credentials: RequestCredentials = 'include';
    const fetchOption = {
        method: method,
        credentials: credentials
    };
    const headers = new Headers();
    headers.append('SS-Platform', 'web');

    if (context) {
        Object.keys(context.req.headers).forEach((key: string) => {
            const value = context.req.headers[key];
            const parsedValue = typeof value === 'string'
                ? value
                : value.join(',');
            headers.append(key, parsedValue);
        });
    } else {
        headers.append('Content-Type', 'application/json');
    }
    if (payload && typeof window !== 'undefined') {
        fetchOption['body'] = JSON.stringify(payload);
    }
    fetchOption['headers'] = headers;
    return fetchOption;
}

/**
 * Cutback coach "rest-api" GET request utility
 * @param apiResource - resource name. If "foo", then "api.cutbackcoach.com/foo" is called.
 * @param params - queryParams
 * @param context - GetServerSidePropsContext if called from SSR, null if called from client side
 */
export async function get(apiResource: string, params?: Record<string, string>, context?: GetServerSidePropsContext): Promise<any> {
    const startTime = new Date().getTime();
    const url = generateUrl(apiResource, params);

    try {
        const res = await fetchWithRetry(
            url.toString(),
            networking.parseFetchOptionFrom('GET', context)
        );
        const endTime = new Date().getTime();

        logFetchTiming(`Get request for ${apiResource}`, endTime - startTime, apiResource);

        return responseToJson(res, apiResource);
    } catch (e) {
        dataDogLogger(
            'error',
            `Frontend Fetch Error: ${e.message} Stack: ${e.stack}`,
            {
                url: url.toString(),
                rawData: params
            }
        );
    }

}

/**
 * Cutback coach "rest-api" POST request utility
 * @param apiResource - resource name. If "foo", then "api.cutbackcoach.com/foo" is called.
 * @param params - queryParams
 * @param context - GetServerSidePropsContext if called from SSR, null if called from client side
 */
export async function post(apiResource: string, payload?: Data | any[], params?: Record<string, string>, context?: GetServerSidePropsContext): Promise<Data> {
    const startTime = new Date().getTime();
    const url = generateUrl(apiResource, params);

    const res = await fetchWithRetry(
        url.toString(),
        networking.parseFetchOptionFrom('POST', context, payload)
    );
    const endTime = new Date().getTime();

    logFetchTiming(`Post request for ${apiResource}`, endTime - startTime, apiResource);

    return responseToJson(res, apiResource);
}

/**
 * Cutback coach "rest-api" PUT request utility
 * @param apiResource - resource name. If "foo", then "api.cutbackcoach.com/foo" is called.
 * @param params - queryParams
 * @param context - GetServerSidePropsContext if called from SSR, null if called from client side
 */
export async function put(apiResource: string, payload: Data, params?: Record<string, string>, context?: GetServerSidePropsContext): Promise<Data> {
    const startTime = new Date().getTime();
    const url = generateUrl(apiResource, params);

    const res = await fetchWithRetry(
        url.toString(),
        networking.parseFetchOptionFrom('PUT', context, payload)
    );
    const endTime = new Date().getTime();

    logFetchTiming(`Put request for ${apiResource}`, endTime - startTime, apiResource);

    return responseToJson(res, apiResource);
}

/**
 * Cutback coach "rest-api" DELETE request utility
 * @param apiResource - resource name. If "foo", then "api.cutbackcoach.com/foo" is called.
 * @param params - queryParams
 * @param context - GetServerSidePropsContext if called from SSR, null if called from client side
 */
export async function del(apiResource: string, params ?: Record < string, string >, context ?: GetServerSidePropsContext): Promise < Data > {
    const startTime = new Date().getTime();
    const url = generateUrl(apiResource, params);

    const res = await fetchWithRetry(
        url.toString(),
        networking.parseFetchOptionFrom('DELETE', context)
    );
    const endTime = new Date().getTime();

    logFetchTiming(`Delete request for ${apiResource}`, endTime - startTime, apiResource);

    return responseToJson(res, apiResource);
}

/**
 * Utility to get UI domain. Designed for setting proper domain for cookies.
 */
export function getWebsiteDomain(): string {
    const hostName = (typeof window !== 'undefined' && window.location && window.location.hostname) || '';
    if (hostName === 'localhost') {
        return hostName;
    }

    /**
     * app.cutbackcoach.com
     * cutbackcoach.com
     *
     * matches cutbackcoach.com
     */
    const processedDomain = '.' + hostName.match(/(\S+\.)?([\S]+\.com?)$/)[2];
    return processedDomain;
}
