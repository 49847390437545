import spacetime from 'spacetime';

// default weekId format
export const YYYYMMDD = '{year}{iso-month}{date-pad}';

// get current weekId based on the MON date
export const getCurrentWeekId = (timeZone?: string) => spacetime
    .now(timeZone)
    .weekStart(1)
    .startOf('week')
    .format(YYYYMMDD)
    .toString();
// get previous weekId based on the MON date
export const getPreviousWeekId = (substractDays?: number, formatAs?: string) => spacetime()
    .subtract(substractDays || 7, 'day')
    .weekStart(1)
    .startOf('week')
    .format(formatAs || YYYYMMDD)
    .toString();
export const getWeekIdForDay = (day: string, formatAs?: string) => spacetime(day)
    .weekStart(1)
    .startOf('week')
    .format(formatAs || YYYYMMDD)
    .toString();