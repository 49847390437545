import Head from 'next/head';

type Props = {
    metaData?: {
        ogTitle?: string,
        ogSiteName?: string,
        ogUrl?: string,
        ogDescription?: string,
        ogType?: string,
        ogImage?: string,
        title?: string,
        disableNoIndex?: boolean,
    }
}

export default function Meta(props: Props): JSX.Element {
    const isStaging = process.env.NEXT_PUBLIC_NENV === 'staging';

    return (
        <Head>
            <meta httpEquiv='X-UA-Compatible' content='IE=edge,chrome=1' />
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            <meta charSet='utf-8' />
            <meta 
                name='description' 
                content='Sleep better, save money, and have more energy with proven techniques that build healthier drinking habits. It’s 100% personalized to you.' />
            {(props.metaData && props.metaData.disableNoIndex) ? <meta /> : <meta name='robots' content='noindex, nofollow' /> }
            {isStaging && <meta name='robots' content='noindex, nofollow' />}
            <meta property='og:title' key='title' content={
                (props.metaData && props.metaData.ogTitle) || 'Sunnyside - Healthier Drinking Habits That Fit Your Lifestyle'
            } />
            <meta property='og:site_name' 
                content={(props.metaData && props.metaData.ogSiteName) 
                || 'Sunnyside'}/>
            <meta property='og:url' content={
                (props.metaData && props.metaData.ogUrl) || 'https://app.sunnyside.co'
            } />
            <meta property='og:description' 
                content={(props.metaData && props.metaData.ogDescription) || 'Sleep better, save money, and have more energy with proven techniques that build healthier drinking habits. It’s 100% personalized to you.'} />
            <meta property='og:type' content={(props.metaData && props.metaData.ogType) || 'website'} />
            <meta property='og:image' key='og-image' content={
                (props.metaData && props.metaData.ogImage) || 
                'https://images.prismic.io/sunnysidetest/63e6349a-7e7b-4ce2-9841-54c570ebcda7_sunnyside-og.jpg?auto=compress,format'
            } />
            <meta name='twitter:card' content='summary' />

            <base href='' />

            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/img/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/img/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest"></link>

            <title>{props.metaData ? props.metaData.title : 'Sunnyside - Healthier Drinking Habits That Fit Your Lifestyle'}</title>
        </Head>
    );
}