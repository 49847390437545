import { post } from '../../networking';
import TikTokEventDto from './types/tik-tok-event.dto';
import cr from 'crypto';
import TikTokEvent from './types/tik-tok-event.enum';

const ENVIRONMENT = process.env.NEXT_PUBLIC_NENV;
const IS_PRODUCTION = ENVIRONMENT === 'production';

const uuidv4 = (): string => {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: any) =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
};
  

const createHash = (text?: string): string | undefined => {
    if (!text) return;
    const hash = cr.createHash('sha256');
    hash.update(text);
    return hash.digest('hex');
};

const createEventId = (event: TikTokEvent): string => {
    const uuid = uuidv4();
    return `${uuid}-${event}-${Date.now()}`;
};

const tikTokEvent = async (user: any, event: TikTokEventDto) => {
    if (!IS_PRODUCTION) {
        console.log('TikTok Event', { event });
        return;
    }
    const ttq = window && window['ttq'];
    const eventId = createEventId(event.event);
    if (ttq) {
        ttq.identify({
            email: createHash(user.email),
            phone: createHash(user.phone),
            external_id: createHash(user.id)
        });
        ttq.track(event.event, {}, { event_id: eventId });
    }
    const pixelId = process.env.NEXT_PUBLIC_TIK_TOK_PIXEL_ID;
    await post('tracking/tik-tok', { ...event, pixelId, eventId });
};

export default tikTokEvent;
