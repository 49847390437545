import { Box, BoxProps } from '@mui/material';
import { variables } from '../../styles/_variables';

interface MaxWidthBoxProps extends BoxProps {
    children: any,
    color?: any,
}

export default function MaxWidthBox(props: MaxWidthBoxProps):JSX.Element {
    const { children } = props;
    return (
        <Box {...props} maxWidth={variables.maxWidth} margin={variables.maxWidthMargin} width='100%'>
            {children}
        </Box>
    );
}